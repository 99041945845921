export const experienceData = [
    {
        id: 1,
        institution: 'Wipro Technology',
        course: 'Project Engineer',
        startYear: 'July - 2021',
        endYear: 'Present'
    },
    {
        id: 2,
        institution: 'Jio',
        course: 'Intern',
        startYear: 'June 2019',
        endYear: 'June 2019'
    },
    
]

export default experienceData;