export const skillsData = [
    'Java',
    'Spring',
    'Hibernate',
    'HTML',
    'CSS',
    'Javascript',
    'React',
    'Jenkins',
    'Docker',
    'Kubernetes',
    'AWS',
    'Github',
    'Git',
    'MySQL',
    'Jira',
    'Maven',
]
